.project-swiper {
  background-color: rgba(63, 63, 63, 0.171);
  padding: 20px 10px;
  border-radius: 30px;
  max-width: 980px;
  margin-inline: auto;

  @include for-mobile-layout {
    max-width: 100%;
    // max-width: fit-content;
  }

  @include for-narrow-layout {
    max-width: 740px;
  }

  @include for-normal-layout {
    max-width: 980px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: var(--clr-main);
  }

  .swiper-pagination-bullet-active {
    background-color: var(--clr-main);
  }
}

.project-preview {
  text-align: start;
  padding: 50px;
  padding-top: 0;

  @include for-mobile-layout {
    width: fit-content;
    margin-inline: auto;
    padding-inline: 0;
  }

  .project-header {
    text-align: center;
    width: 100%;
    margin-bottom: 30px;

    .title {
      font-size: 48px;
      flex: 1;

      @include for-mobile-layout {
        font-size: 32px;
      }
    }

    .subtitle {
      @include for-mobile-layout {
        font-weight: 400;
        font-family: Roboto;
        color: var(--clr-txt-secondary);
      }
    }

    .url {
      font-size: 40px;
      color: var(--clr-main);

      @include for-mobile-layout {
        font-size: 26px;
        translate: 20px -5px;
      }
    }
  }

  .details {
    gap: 40px;
    flex-direction: column;
    align-items: center;

    @include for-normal-layout {
      flex-direction: row;
    }

    .thumbnail {
      width: 540px;

      @include for-mobile-layout {
        width: 100%;
        height: 439px;
      }

      .img-thumbnail {
        object-fit: contain;
        width: 100%;
        height: 100%;

        @include for-narrow-layout {
          width: 540px;
          height: 435px;
        }

      }

      .phone-thumbnail {
        position: relative;
        display: grid;
        place-items: center;
        // width: 540px;
        height: 439px;

        @include for-narrow-layout {
          width: 540px;
        }

        img {
          width: 200px;
          position: absolute;
        }

        video {
          width: 178px;
          position: absolute;
          border-radius: 10px;
        }
      }
    }
  }

  .info {
    flex: 1;
    justify-content: flex-end;
    height: 435px;
    gap: 10px;

    .snapshots {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 10px;
      margin-block: auto;

      >img {
        width: 135px;
        height: 108px;
        object-fit: cover;
        border-radius: 8px;

        &:not(.active) {
          mask-image: linear-gradient(to bottom, rgb(0, 0, 0) 0%, rgba(0, 0, 0, 0) 100%);
        }
      }
    }

    .description {
      margin-block: auto;

    }

    .subtitle {
      color: var(--clr-txt-secondary);
      font-size: 16px;
      font-weight: 200;
      font-family: Roboto-Light;
    }

    .btn.close {
      color: var(--clr-main);
    }

    .btn.read-more {
      animation: blink 2s ease-in-out infinite;

      @keyframes blink {
        0% {
          color: white;
        }

        50% {
          color: var(--clr-main)
        }

        100% {
          color: white;
        }
      }
    }


    .tag-list {
      gap: 14px;
      font-size: 12px;
      font-family: Roboto;
      flex-wrap: wrap;

      .tag {
        line-height: 0;
        padding: 5px 4px;
        border-radius: 4px;
        background-color: var(--clr-main);
        color: var(--clr-gray);

        .icon {
          margin-inline-end: 4px;
        }
      }
    }
  }
}