.about-me {
  gap: 90px;
  padding-block-start: 120px;
  padding-block-end: 180px;
  
  @include for-mobile-layout {
    padding-block-start: 80px;
    padding-block-end: 140px;
  }
  .main-title {
    @include section-title
  }

  .content-container {
    gap: 40px;
    margin-bottom: 80px;
    @include for-mobile-layout {
      flex-direction: column;
      margin-bottom: 40px;
    }
    .profile-img {
      width: 170px;
      border-radius: 50%;
    }

    .description {
      font-family: Roboto-Slab;
      font-size: 20px;
      font-weight: 300;
      color: var(--clr-txt-secondary);
    }
  }

  .software {
    width: 100%;
    flex-wrap: wrap;
    gap: 6px;

    a {
      opacity: 0;
      translate: 0 10px;
      height: 40px;
      transition: all .4s ease-out
    }

    &.visible {
      a {
        opacity: 1;
        translate: 0 0;
      }

      @for $i from 1 through 21 {
        a:nth-child(#{$i}) {
          transition-delay: calc(($i - 1) * .08s);
        }
      }
    }


    .white-bg img {
      border-radius: 8px;
      background-color: white;
    }
  }
}