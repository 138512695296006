.app-header-container {
  position: fixed;
  top: 0;
  z-index: 1000;
  color: white;
  transition: background-color .2s ease-out;

  &.scroll {
    background-color: lighten($clr-secondary, 4);
    box-shadow: 0px 0px 16px 6px rgba(0, 0, 0, 0.52);
  }

  @include for-mobile-layout {
    .nav-btns {
      top: 90px;
      background-color: rgba(53, 53, 53, 0.342);
    }

    &.scroll {
      .nav-btns {
        background-color: lighten($clr-secondary, 4);
        box-shadow: -10px 8px 12px -5px rgba(0, 0, 0, 0.52);
        top: 60px;
      }
    }
  }
}

.app-header {
  padding: 25px 50px;
  transition: padding .3s ease-out;

  @include for-mobile-layout {
    padding: 25px 30px;
    overflow: hidden;
  }

  &.scroll {
    padding: 10px $layoutPadding;

    @include for-mobile-layout {
      padding: 10px $layoutPaddingMobile;
    }

    .svg-icon #right * {
      fill: var(--clr-main);
    }
  }

  .hamburger {
    display: none;

    @include for-mobile-layout {
      display: grid;
      font-size: 30px;
    }

    svg {
      >* {
        transition: all .3s ease-out;
      }
    }

    &.open {
      svg {

        >*:first-child {
          transform-origin: center;
          transform: translate(-50%, 10%) rotate(-45deg) scale(2.1, 1);
        }

        >*:nth-child(2) {
          opacity: 0;
        }

        >*:last-child {
          transform-origin: center;
          transform: translate(-30%) rotate(45deg) scale(1.5, 1);
        }
      }
    }
  }

  .logo-btn {
    font-family: Dancing-Script, Roboto-Slab, Roboto;
    font-size: 16px;
    font-weight: 700;

    .svg-icon {
      width: 32px;
    }


    &:hover {
      svg * {
        fill: var(--clr-main);
      }
    }
  }

  .nav-btns {
    gap: 10px;

    @include for-mobile-layout {
      align-items: flex-start;
      padding: 15px;
      padding-inline-end: 95px;
      border-bottom-left-radius: 8px;
      right: -60%;
      transition: top .3s ease-out, right .5s ease-out;
      position: absolute;
      flex-direction: column;

      &.open {
        display: flex;
        right: 0px;
        z-index: -1;
      }

      .nav-link {
        text-decoration: underline;
      }
    }
  }

  .nav-link {
    cursor: pointer;
    transition: color .3s ease-out;

    &:hover,
    &.active {
      color: var(--clr-main);
    }
  }
}