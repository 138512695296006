* {
  box-sizing: border-box;
  // scroll-behavior: smooth;
}

body {
  margin: 0;
  color: white;
  font-family: -apple-system, Roboto, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  background-color: var(--clr-main-bg);
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

button {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  cursor: pointer;
  background-color: transparent;
  border: none;
  padding: 0;
  color: inherit;
}

.font-test {
  text-transform: capitalize;
  font-size: 30px;
  text-align: end;
  padding-inline-end: 40px;
}

.roboto {
  font-family: Roboto;
}

.roboto-light {
  font-family: Roboto-Light;
}

.roboto-medium {
  font-family: Roboto-medium;
}

.roboto-bold {
  font-family: Roboto-bold;
}

.roboto-slab {
  font-family: Roboto-slab;
}

.dancing-script {
  font-family: dancing-script;
}