.hero-container {
  position: relative;
  background-image: url("../../assets/img/hero-bg.png");
  background-attachment: scroll;
  background-position: center center;


  .hero {
    height: max(100vh, 860px);
    color: white;

    .intro {
      padding-top: 100px;
      height: 100%;
      position: relative;

      .hello {
        font-size: 120px;
        font-family: Roboto-Bold;
        text-transform: uppercase;
        color: #ffffff18;
        letter-spacing: -7px;

        .highlight-text {
          color: var(--clr-main);
        }

        @include for-mobile-layout {
          letter-spacing: -4px;
          font-size: 90px;
        }

        @include for-narrow-layout {
          letter-spacing: -10px;
          font-size: 160px;
        }

        @include for-normal-layout {
          font-size: 200px;
        }
      }
    }

    .about {
      h2 {
        font-size: 40px;
        font-family: Roboto-Medium;

        @include for-mobile-layout {
          font-size: 32px;
        }

        @include for-narrow-layout {
          font-size: 60px;
        }
      }

      .highlight-text {
        color: var(--clr-main);
        margin-bottom: 50px;

        &::after {
          content: '';
          display: block;
          position: relative;
          translate: 0 20px;
          width: 160px;
          height: 6px;
          border-radius: 2em;
          background-color: var(--clr-main);
        }
      }

      .job-description {
        width: 300px;
        font-family: Roboto-Light;
        font-size: 16px;
        letter-spacing: .5px;

        @include for-narrow-layout {
          font-size: 18px;
        }
      }
    }

    .follow-me {
      position: absolute;
      z-index: 10;
      top: 100px;
      right: 0;
      gap: 20px;

      @include for-mobile-layout {
        right: -5px;
      }

      .title {
        writing-mode: vertical-rl;
      }

      .spacer {
        width: 6px;
        height: 80px;
        border-radius: 2em;
        background-color: var(--clr-main);
      }

      .svg-link {
        font-size: 20px;
      }
    }
  }

  .overflow-container {
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;
    top: 650px;
    border-top: 1px solid var(--clr-main);
    background-color: var(--clr-secondary);
  }

  .overflow {
    position: relative;
    align-items: center;
    height: 100%;

    @include for-mobile-layout {
      flex-direction: column-reverse;
    }

    .quote-container {
      @include for-mobile-layout {
        padding-bottom: 20px;
      }

      .author {
        color: var(--clr-txt-secondary);
        font-family: Roboto-Light;
        font-weight: 300;
        margin-bottom: 6px;
      }

      .quote {
        color: var(--clr-txt-main);
        font-family: Roboto-Slab;
        font-weight: 600;
      }
    }

    .img-container {
      position: relative;
      translate: 0 -40%;

      @include for-mobile-layout {
        position: absolute;
        translate: 0 -50%;
      }

      @include for-normal-layout {
        position: absolute;
        right: 42px;
        translate: 0 -55%;
      }

      .hero-img {
        width: 500px;

        @include for-mobile-layout {
          width: 300px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        display: block;
        border-bottom-right-radius: 90px;
        width: 300px;
        height: 300px;
        translate: 101px 60%;
        box-shadow: 1px 1px 0px 0px var(--clr-main);
        outline-offset: 40px;
        outline: 1px solid var(--clr-main);
        rotate: 45deg;
        z-index: -1;

        @include for-mobile-layout {
          width: 200px;
          height: 200px;
          translate: 50px 50%;
          border-bottom-right-radius: 60px;
        }
      }
    }
  }
}