.main-layout {
  padding-inline: $layoutPadding;

  @include for-mobile-layout {
    padding-inline: $layoutPaddingMobile;
  }

  &.full {
    width: 100%;
  }

  &:not(.full) {
    max-width: $padded-break-wide;
    margin-inline: auto;
  }
}