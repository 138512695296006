.specialties-container {
  background-image: url(../../assets//img/specialties-bg.png);
  background-repeat: no-repeat;
  background-size: auto 80%;
  background-position: center left;
}

.specialties {
  padding-block-start: 90px;
  padding-block-end: 120px;
  @include for-mobile-layout {
    padding-block-end: 60px;
  }
  .main-title {
    @include section-title;
    margin-bottom: 80px;
  }

  .specialties-list {
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 60px;

    @include for-mobile-layout {
      flex-direction: column;
      align-items: center;
      gap: 30px;
    }

    .specialty {
      text-align: center;
      position: relative;
      width: 200px;
      aspect-ratio: 1;
      border-radius: 2em;
      background-color: var(--clr-secondary);

      @include for-mobile-layout {
        &:nth-child(2n) {
          margin-inline-start: 35%;
        }
        
        &:nth-child(2n - 1) {
          margin-inline-end: 35%;
        }
      }

      @include for-normal-layout {
        width: 250px;
      }

      .icon {
        font-size: 50px;

        @include for-normal-layout {
          font-size: 80px;
        }

        svg * {
          fill: var(--clr-main);
        }
      }

      .title {
        margin-bottom: 10px;
      }

      .description {
        width: 80%;
        color: var(--clr-txt-secondary);
        font-family: Roboto-Light;
      }
    }
  }

  .skill-list {
    gap: 10px;

    &.invisible {
      .score {
        width: 0px !important;
      }
    }

    .skill {
      justify-content: space-between;
      gap: 30px;
      position: relative;

      .score-container {
        height: 6px;
        width: 200px;
        background-color: var(--clr-txt-secondary);
        border-radius: 2em;
      }

      .score {
        background-color: var(--clr-main);
        height: 100%;
        border-radius: 2em;
      }
    }
  }
}


//? Animation
.specialties-list {
  .specialty {
    transition: opacity .8s ease-out, translate .5s ease-out;

    .title {
      transition: opacity .5s ease-out, translate .5s ease-out;
    }

    .description {
      transition: opacity .6s ease-out, translate .5s ease-out;
    }
  }

  &.visible {
    @for $i from 1 through 3 {
      .specialty:nth-child(#{$i}) {
        transition-delay: calc(0.2s + ($i - 1) * .6s);

        .title {
          transition-delay: calc(0.4s + ($i - 1) * .6s);
        }

        .description {
          transition-delay: calc(.6s + ($i - 1) * .6s);
        }
      }
    }

    @include float-animation;
    @include loading-bar-animation;
  }

  &.invisible {
    .specialty {
      transition: opacity .3s ease-out, translate .5s ease-out;
      opacity: 0;
    }

    .title {
      translate: -20px 0;
      opacity: 0;
    }

    .description {
      translate: 0 10px;
      opacity: 0;
    }
  }
}

.skill-list {
  &.invisible {
    .score {
      width: 0px !important;
    }
  }

  .skill {

    .score-container {
      border-radius: 2em;
    }

    .score {
      transition: width 2s ease-out;
    }
  }
}