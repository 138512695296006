:root {
  --clr-main-bg: #1D222A;
  --clr-main: #00ADB5;
  --clr-secondary: #222831;
  --clr-light: #EEEEEE;
  --clr-gray: #1B1B1B;
  --clr-txt-main: #dadada;
  --clr-txt-secondary: #a8a8a8;
}
//? Colors
$clr-main-bg: #1D222A;
$clr-main: #00ADB5;
$clr-secondary: #222831;
$clr-light: #EEEEEE;
$clr-gray: #1B1B1B;
$clr-txt-main: #dadada;
$clr-txt-secondary: #a8a8a8;

//? Layout
$layoutPadding: 20px;
$layoutPaddingMobile: 10px;

//? Layout Breakpoints
$break-narrow: 830px;
// $break-mid: 950px;
$break-normal: 1070px;
$break-wide: 1180px;

$padded-break-narrow: $break-narrow + $layoutPadding * 2;
$padded-break-normal: $break-normal + $layoutPadding * 2;
$padded-break-wide: $break-wide + $layoutPadding * 2;