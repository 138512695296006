.portfolio-container {
  background-color: var(--clr-secondary);
}

.portfolio {
  text-align: center;
  gap: 20px;
  padding-block-start: 90px;
  padding-block-end: 120px;

  .header {
    margin-bottom: 60px;

    .main-title {
      @include section-title;
    }

    .sub-title {
      @include section-sub-title;
    }
  }
}