.btn-contact-me-container {
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;

  .btn-contact-me {
    font-size: 28px;
    padding: 15px;
    border-radius: 50%;
    background-color: var(--clr-main);

    &.open::after {
      content: 'Close';
    }

    &::after {
      content: 'Contact Me';
      font-family: Roboto-Light;
      font-size: 14px;
      position: absolute;
      left: 0;
      opacity: 0;
      transition: all .4s ease-in-out;
    }

    &.open:hover::after {
      left: -80%;
    }

    &:hover::after {
      opacity: 1;
      left: -130%;
    }
  }
}

.contact-me {

  .title {
    margin-bottom: 30px;
    font-size: 32px;
  }

  .form {
    gap: 5px;
    text-align: start;

    input, .message {
      padding: 4px 6px;
      margin-bottom: 20px;

      &:focus-visible {
        outline: none;
        border: 2px solid var(--clr-main);
        border-radius: 4px;
      }
    }

    .btn-submit {
      border-radius: 50%;
      color: var(--clr-main);
      font-size: 36px;
      margin: 10px auto;
    }

    .message {
      width: 280px;
      height: 100px;
      font-size: 14px;
    }
  }
}

.modal-container {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 10;

  &.open {
    transform: scale(1);

    .modal-background {
      background: rgba(0, 0, 0, .0);
      animation: fadeIn .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

      .modal {
        background-color: transparent;
        animation: modalFadeIn .5s .8s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        h1,
        form {
          opacity: 0;
          position: relative;
          animation: modalContentFadeIn .5s 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
        }

        .modal-svg {
          rect {
            animation: sketchIn .5s .3s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }
        }
      }
    }

    &.out {
      animation: quickScaleDown 0s .5s linear forwards;

      .modal-background {
        animation: fadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        .modal {
          animation: modalFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

          h1,
          form {
            animation: modalContentFadeOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
          }

          .modal-svg {
            rect {
              animation: sketchOut .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;
            }
          }
        }
      }
    }
  }

  .modal-background {
    display: table-cell;
    background: rgba(0, 0, 0, .8);
    text-align: center;
    vertical-align: middle;

    .modal {
      background: white;
      padding: 40px;
      display: inline-block;
      border-radius: 6px;
      font-weight: 300;
      position: relative;

      .modal-svg {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        border-radius: 6px;

        rect {
          stroke: $clr-main-bg;
          stroke-width: 5px;
          stroke-dasharray: 1800;
          stroke-dashoffset: 1800;
          // fill: rgb(56, 56, 56);
        }
      }
    }
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }

  99.9% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, .0);
  }

  100% {
    background: rgba(0, 0, 0, .7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, .7);
  }

  100% {
    background: rgba(0, 0, 0, .0);
  }
}

@keyframes sketchIn {
  0% {
    stroke-dashoffset: 1800;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes sketchOut {
  0% {
    stroke-dashoffset: 0;
  }

  100% {
    stroke-dashoffset: 1800;
  }
}

@keyframes modalFadeIn {
  0% {
    background-color: transparent;
  }

  100% {
    background-color: lighten($clr-secondary, 4);
  }
}

@keyframes modalFadeOut {
  0% {
    background-color: lighten($clr-secondary, 4);
  }

  100% {
    background-color: transparent;
  }
}

@keyframes modalContentFadeIn {
  0% {
    opacity: 0;
    top: -20px;
  }

  100% {
    opacity: 1;
    top: 0;
  }
}

@keyframes modalContentFadeOut {
  0% {
    opacity: 1;
    top: 0px;
  }

  100% {
    opacity: 0;
    top: -20px;
  }
}