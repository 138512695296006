.footer-container {
  background-color: lighten($clr-secondary, 4);
}

.footer {
  padding-block: 40px;

  .social-network {
    text-align: center;

    h3 {
      margin-bottom: 10px;
    }

    .links {
      font-size: 20px;
      gap: 8px;

      .svg-link {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 6px;
        background-color: var(--clr-main);
      }
    }
  }

  .copyright {
    font-size: 14px;
    color: var(--clr-txt-secondary);
  }
}