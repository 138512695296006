//? Media Queries Breakpoint

@mixin for-mobile-layout { // 830px
  @media (max-width: $break-narrow) {
    @content;
  }
}

@mixin for-narrow-layout { // 830px
  @media (min-width: $break-narrow) {
    @content;
  }
}

@mixin for-normal-layout { // 1070px
  @media (min-width: $break-normal) {
    @content;
  }
}

@mixin for-wide-layout { // 1180px
  @media (min-width: $break-wide) {
    @content;
  }
}

//? Helpers Mixins

@mixin section-title {
  font-size: 60px;
  text-transform: uppercase;
  font-family: Roboto-Bold;
  letter-spacing: -5px;
}

@mixin section-sub-title {
  font-family: Roboto-Light;
  font-size: 16px;
  color: var(--clr-txt-secondary);
}

@mixin nav-hover {
  color: var(--clr-main)
}


//? Keyframe Animations

@mixin float-animation {
  @keyframes float {
    0% {
      translate: 0 2%;
    }

    50% {
      translate: 0 -2%;
    }

    100% {
      translate: 0 2%;
    }
  }
}

@mixin loading-bar-animation {
  @keyframes loading-bar {
    from {
      translate: -50% 0;
    }

    to {
      translate: 0 0;
    }
  }
}