
@font-face {
  font-family: Roboto;
  src: url(../../assets/fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(../../assets/fonts/Roboto/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(../../assets/fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(../../assets/fonts/Roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-Slab;
  src: url(../../assets/fonts/RobotoSlab.ttf);
}

@font-face {
  font-family: Dancing-Script;
  src: url(../../assets/fonts/DancingScript.ttf);
}