.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex.justify-center,
.flex-column.justify-center {
  justify-content: center;
}

.flex.align-center,
.flex-column.align-center {
  align-items: center;
}

.flex.space-between,
.flex-column.space-between {
  justify-content: space-between;
}

.flex.space-around,
.flex-column.space-around {
  justify-content: space-around;
}

.btn-svg {
  display: grid;
  place-items: center;
}